import React from "react";
import logo from "./Login.jpg";

const Login = () => {
  return (
    <div style={{ marginTop: "10vh" }}>
      <div>
        <title>Therion Admin - Login</title>
      </div>

      <div class="container">
        {/* <!-- Outer Row --> */}
        <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
              <div class="card-body p-0">
                {/* <!-- Nested Row within Card Body --> */}
                <div class="row">
                  <img class="col-lg-6" src={logo}></img>
                  <div class="col-lg-6">
                    <div class="p-5">
                      <div class="text-center">
                        <h1 class="h4 text-gray-900 mb-4">
                          Login To M<sup>2</sup> Dashboard
                        </h1>
                      </div>
                      <form class="user">
                        <div class="form-group">
                          <input
                            type="username"
                            class="form-control form-control-user"
                            id="exampleInputEmail"
                            aria-describedby="emailHelp"
                            placeholder="Enter Username"
                          />
                        </div>
                        <div class="form-group">
                          <input
                            type="password"
                            class="form-control form-control-user"
                            id="exampleInputPassword"
                            placeholder="Password"
                          />
                        </div>
                        <div class="form-group">
                          <div class="custom-control custom-checkbox small">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customCheck"
                            />
                            <label
                              class="custom-control-label"
                              for="customCheck"
                            >
                              Remember Me
                            </label>
                          </div>
                        </div>
                        <a
                          href="index.html"
                          class="btn btn-primary btn-user btn-block"
                        >
                          Login
                        </a>
                        <hr />
                        {/* <a
                          href="index.html"
                          class="btn btn-google btn-user btn-block"
                        >
                          <i class="fab fa-google fa-fw"></i> Login with Google
                        </a>
                        <a
                          href="index.html"
                          class="btn btn-facebook btn-user btn-block"
                        >
                          <i class="fab fa-facebook-f fa-fw"></i> Login with
                          Facebook
                        </a> */}
                      </form>
                      <hr />
                      <div class="text-center">
                        <a class="small" href="forgot-password.html">
                          Forgot Password?
                        </a>
                      </div>
                      <div class="text-center">
                        <a class="small" href="register.html">
                          Create an Account!
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
